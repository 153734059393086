import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import '../scss/AppCollectionSlider.scss';

//Import images
import img1 from "../imgs/slid/03.jpg";
import img2 from "../imgs/slid/3.jpg";
import img3  from "../imgs/slid/01.jpg";

//import font awsome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { faGithub } from "@fortawesome/free-brands-svg-icons";


// import required modules
import { EffectCoverflow, Pagination, Navigation } from 'swiper/modules';

import { useTranslation } from "react-i18next";

function AppCollectionSlider() {

  const { t } = useTranslation();

  return (
    <div className='AppCollectionSlider' id='appCollectionSlider'>
        <div className='titles'>
            <h3 className='subTitle' data-aos="zoom-in" data-aos-delay="400">{t("The Best Apps at Your Fingertips")}</h3>
            <h2 className='title' data-aos="zoom-in" data-aos-delay="600">{t("App Collections.")}</h2>
       </div>
    <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 50,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        breakpoints={{
          // When window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // When window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // When window width is >= 768px
          768: {
            slidesPerView: 3,
            spaceBetween: 0,
          },
          // More breakpoints as needed
        }}
        loop={true}
        initialSlide={1}
        pagination={false}
        navigation={true}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>         
            <div class="team-box BibooTV">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/datasafety?id=com.technopresso.bibootv&pli=1">{/*Bibootv url here*/}
                        {t("BibooTV")}
                        </a>
                    </h2>
                    <div className='description'>
                    <p>
                        {t("BibooTV description1")}
                    </p>
                    <p>
                    {t("BibooTV description2")}                  
                    </p>
                    <p>
                        <a target="_blank" href="https://play.google.com/store/apps/datasafety?id=com.technopresso.bibootv&pli=1">{/*BibooTV url here*/}
                        {t("Check this app")}
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>         
            <div class="team-box Dsprintly">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://apps.apple.com/ph/app/dsprintly/id6470173764">{/*Dsprintly url here*/}
                        {t("Dsprintly")} 
                        </a>
                    </h2>
                    <div className='description'>
                    
                    <p>
                    {t("Dsprintly description1")}
                    </p>
                    <p>
                    {t("Dsprintly description2")}
                    </p>
                    <p>
                        <a target="_blank" href="https://apps.apple.com/ph/app/dsprintly/id6470173764">{/*Dsprintly url here*/}
                        {t("Check this app")}
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>         
            <div class="team-box Dsuit">
            <div class="team-name">
                <center>
                <div class="team-content">   
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.dsuit">
                        {t("Dsuit")}    
                        </a>
                   </h2>
                    <div className='description'>
                        <p>
                        {t("Dsuit description")}
                        </p>
                        <p>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.dsuit">              
                            {t("Check this app")}
                            </a> 
                        </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>

        <SwiperSlide>         
            <div class="team-box Deliveroo">
            <div class="team-name">
                <center>
                <div class="team-content"> 
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.deliverooclone">
                        {t("Deliveroo Clone")}
                        </a>
                    </h2>
                    <div className='description'>
                        <p>{t("Deliveroo description")}</p>
                        <p>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.deliverooclone">
                            {t("Check this app")}
                            </a>
                        </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>

        
        <SwiperSlide>         
            <div class="team-box JolieStores">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href= "https://play.google.com/store/apps/details?id=com.technopresso.joliestore">
                        {t("Jolie Stores")}
                        </a>
                    </h2>
                   
                    <div className='description'>
                    <p>
                    {t("Jolie description")}
                    </p>
                    <p>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.joliestore">
                        Check This App
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        <SwiperSlide>
                    <div class="team-box Twicher">
                    <div class="team-name">
                        <center>
                        <div class="team-content">
                            <h2>
                                <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.twicher&hl=en&gl=US"> {/*Twicher url here*/}
                                {t("Twicher")}
                                </a>
                           </h2>
                            <div className='description'>
                                <p>
                                {t("Twicher description")}
                                </p>
                                <p>
                                    <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.twicher&hl=en&gl=US"> {/*Twicher url here*/}
                                    {t("Check this app")}
                                    </a>
                                </p>
                            </div>
                        </div>
                        </center>
                    </div>
                    </div>
                </SwiperSlide>

        
       {/*  <SwiperSlide></<SwiperSlide> */}
        {/*  below is all apps that we decided not to show to customers
        <SwiperSlide>         
            <div class="team-box team-box6">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.technopressoInstaclone">
                        Instagram Clone
                        </a>
                    </h2>
                    <div className='description'>
                        <p>
                        By setting up AuthNavigation, an application that mimics the SNS service app Instagram, users who are not logged in cannot view the content. You can post photos by pressing the + icon.
                        </p>
                        <p>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.technopressoInstaclone">                       
                            https://play.google.com/store/apps/details?id=com.technopresso.technopressoInstaclone
                            </a>
                        </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        */}

                {/* 
        <SwiperSlide>         
            <div class="team-box team-box1">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.overoo">
                        Uber Clone
                        </a>
                    </h2>
                    <div className='description'>
                    <p>
                    An application that mimics the ride-hailing service Uber
                    By using GooglePlacesAutocomplete, candidate locations are displayed according to the words searched by the user.
                    Arrival time, distance, and route to the destination will be displayed by using matrix api.
                    </p>
                    <p>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.overoo">
                        https://play.google.com/store/apps/details?id=com.technopresso.overoo
                        </a>
                    </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        */}
        {/* 
        <SwiperSlide>         
            <div class="team-box team-box2">
            <div class="team-name">
                <center>
                <div class="team-content">
                    <h2>
                        <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.munchbytes&pli=1">
                        Munch Bytes
                        </a>
                    </h2>
                    <div className='description'>
                        <p>
                        Food delivery service application MunchByte. Dedicated pages for users and sellers, and you can easily edit product information such as food images and prices from the management page. We have an online payment system as well as a delivery system. Compatible with iOS and Android
                        </p>
                        <p>
                            <a target="_blank" href="https://play.google.com/store/apps/details?id=com.technopresso.munchbytes&pli=1">
                            https://9play.google.com/store/apps/details?id=com.technopresso.munchbytes&pli=1
                            </a>
                        </p>
                    </div>
                </div>
                </center>
            </div>
            </div>
        </SwiperSlide>
        */} 

      </Swiper>
    </div>
  )
}

export default AppCollectionSlider;