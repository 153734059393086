import React from 'react'

import AnimatedCursor from "react-animated-cursor"

function AnimeCursor() {
  return (
    <AnimatedCursor
      innerSize={8}
      outerSize={50}
      color=' 117, 218, 180' 
      outerAlpha={0.2}
      innerScale={0.7}
      outerScale={2}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link'
      ]}
     /> //https://www.npmjs.com/package/react-animated-cursor
  )
}

export default AnimeCursor