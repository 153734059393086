import React from 'react'
import {Carousel } from 'react-bootstrap';
import { Button } from 'react-bootstrap';

import img1 from "../imgs/slid/01.jpg";
import img2 from "../imgs/slid/3.jpg";
import img3  from "../imgs/slid/2.jpg";
import img4  from "../imgs/slid/03.jpg";

import "../scss/SlideShow.scss";
//import 'bootstrap/dist/css/bootstrap.css';

//react-scroll
import { Link } from 'react-scroll';

import { useTranslation } from 'react-i18next';

function Slideshow() {
    const { t } = useTranslation();

    //ここのcaptionとdescriptionはもう関係ない i18nextのmain.json(ja/en)確認
    const images = [
        { id:1, src: img1,  alt:'slide 1 dayo', caption:'About Us', description:'Aim to be the leading Software developer in the industry', idTag:"aboutUs"},
        { id:2, src: img2,  alt:'slide 2 dayo', caption:'Our Service', description:'Build your Project with Low Cost and High Quality', idTag:"service"},
        { id:3, src: img3,  alt:'slide 3 dayo', caption:'Our Collections', description:'Check APP and Web Collections', idTag:"appCollectionSlider"},
        { id:4, src: img4,  alt:'slide 4 dayo', caption:'Contact Us', description:'Get In Touch With Us', idTag:"contact"},
    ];

    const componentStyle = {
        //position: 'relative',A
        //top: '-54px',
        //zIndex: -1,
    };

  return (
    <Carousel controls={true} style={componentStyle} className='SlideShow' id='slideShow'>
     {images.map((image) => (
        <Carousel.Item key={image.id}>
          <img src={image.src} alt={image.alt} />
          <Carousel.Caption className="carousel-caption">
            <h2>{t(`slides.${image.idTag}.caption`)}</h2>
            <p>{t(`slides.${image.idTag}.description`)}</p>
            <Link to={image.idTag} spy="true" smooth="true" offset={50} duration={200}>
              <Button className="custom-button">{t('slides.learnMore')}</Button>
            </Link>
          </Carousel.Caption>
          <div className='overlay'></div>
        </Carousel.Item>
      ))} 
    </Carousel>
  );
}

export default Slideshow