import React, { useState } from 'react'
import { Button, Modal } from "react-bootstrap";

import "../scss/WebCollection2.scss"
import "../scss/Modal.scss";

import imgAgentAi from  "../imgs/WebCollection/Mookda/agentai.png";
import imgMookda from  "../imgs/WebCollection/Mookda/Mookda1.png";
import imgAos from  "../imgs/WebCollection/Aos/wedding.jpg";
import imgSlideAnimation from  "../imgs/WebCollection/Company2/Company2-2.jpg";
import imgBlog1 from  "../imgs/WebCollection/Blog1/Blog1-1.png";
import imgBlog2 from  "../imgs/WebCollection/Blog2/Blog2-2.png";
import imgHair from  "../imgs/WebCollection/HairSalon/HairSalon1.png";
import imgMusic from  "../imgs/WebCollection/Music/Music1.png";
import imgCompany from  "../imgs/WebCollection/Company1/Company1-1.png";
import imgTravel from  "../imgs/WebCollection/Travel1/Travel1-1.png";
//import imgAmazon from "../imgs/WebCollection/Amazon/Amazon1.png";
//import imgBlog3 from  "../imgs/WebCollection/Blog3/Blog3-1.png";
//import imgTravel2 from  "../imgs/WebCollection/Travel2/Travel2-1.png";

import AnimatedCursor from "react-animated-cursor"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';

export default function Collection() {
    const { t } = useTranslation();
    
    const data = [ 
        { id:1, src: imgAgentAi,  alt:'AgentAi', caption:'AgentAi', description:'AgentAi description', url:"https://witty-basilisk-popular.ngrok-free.app/index.html"},
        { id:2, src: imgMookda,  alt:'Mookda', caption:'Mookda', description:'Mookda description', url:"https://mookdajapan.web.app/"},
        { id:3, src: imgAos,  alt:'Wedding Website', caption:'Wedding Website', description:'AOS animation description', url:"https://ghalreekandjessicawedding.com/"},
        { id:4, src: imgSlideAnimation,  alt:'Slide Animation', caption:'Slide Animation', description:'Slide Animation description', url:"https://technopresso-portfolio-dark-03.web.app/index.html"},
        { id:5, src: imgBlog1,  alt:'Blog - 1', caption:'Blog - 1', description:'Blog description1', url:"https://mari.vip/"},
        { id:6, src: imgBlog2,  alt:'Blog - 2', caption:'Blog - 2', description:'Blog description2', url:"https://netlify-urbangarden.netlify.app/"},
        { id:7, src: imgHair,  alt:'Hair salon site', caption:'Hair Salon', description:'Hair Salon description', url:"https://beauty-salon-bf735.web.app/"},
        { id:8, src: imgMusic,  alt:'Music', caption:'Music', description:'Music description', url:"https://music-site-72349.firebaseapp.com/"},
        { id:9, src: imgCompany,  alt:'Company', caption:'Company', description:'Company description', url:"https://team-portfolio-fcbf8.web.app/"},
//        { id:9, src: imgTravel,  alt:'Travel', caption:'Travel', description:'Travel description', url:"https://travel-site-5da71.web.app/"},
        //{ id:9, src: img9,  alt:'Travel-1', caption:'Travel - 1', description:'travel description1', url:"https://cebupot-e49ee.web.app/#/"},
        //{ id:1, src: img1,  alt:'Amazon Clone', caption:'Amazon Clone', description:'amazon description', url:""},
        //{ id:6, src: img7,  alt:'Blog - 3', caption:'Blog - 3', description:'blog description3', url:"https://peaceful-elion-5d4688.netlify.app/"},
    ];

    const [showModal, setShowModal] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);

    const handleOpenModal = (id) => {
        setSelectedItemId(id);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

  return (
    <>
    <div className='webCollectionContainer'>
       
       <div className='titles'>
            <h3 className='subTitle' data-aos="zoom-in" data-aos-delay="600">{t('collection.inspiringWebsites')}</h3>
            <h2 className='title' data-aos="zoom-in" data-aos-delay="800">{t('collection.webCollections')}</h2>
       </div>
  
        <div className='box-container' data-aos-offset="250">
            {data.map((item) => ( 
                <div className='box' key={item.id}  onClick={() => handleOpenModal(item.id)} data-aos="fade-right" data-aos-delay="800">
                    <div className='text-container'>  
                        <h3>{t(`collection.${item.caption}`)}</h3>
                    </div> 
                    <img src={item.src}/>              
                    <div className='card-overlay'></div>
                </div> 
            ))}
        </div>

        <Modal show={showModal} onHide={handleCloseModal} className='customModal'>
            {data.filter((item) => item.id === selectedItemId)
                .map((item) => (
                    <div className='modalContainer'>
                    <Modal.Header>
                    <Modal.Title className='modalTitle'>
                        <h4 className=''>{t(`collection.${item.caption}`)}</h4>
                        <p><FontAwesomeIcon icon={ faXmark } className='modalIcon' onClick={handleCloseModal} /></p>
                    </Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='modalBody'>
                    <div className=''>         
                        <div className='' key={item.id} >
                            <img src={item.src} alt={item.alt}/>
                            <h4>{t(`collection.${item.caption}`)}</h4>
                            <p>{t(`collection.${item.description}`)}</p>
                            <button><a target="_blank" href={item.url}>{t('collection.checkWebsite')}</a></button>
                        </div>
                    </div>
                    </Modal.Body>
                    <Modal.Footer>
                    </Modal.Footer>
                    </div>
                ))
            }  
            
            <AnimatedCursor
                innerSize={8}
                outerSize={50}
                color=' 117, 218, 180' 
                outerAlpha={0.2}
                innerScale={0.7}
                outerScale={2}
                clickables={[
                    'a',
                    'input[type="text"]',
                    'input[type="email"]',
                    'input[type="number"]',
                    'input[type="submit"]',
                    'input[type="image"]',
                    'label[for]',
                    'select',
                    'textarea',
                    'button',
                    '.link'
            ]} />
        </Modal>

    </div>
    </>
  )
}
