import React from 'react';
import Slideshow from '../components/Slideshow';
import AboutUs from '../components/AboutUs';
import Service from '../components/Service';
import CustomerBenefit from '../components/CustomerBenefit';
import OurProgrammers from "../components/OurProgrammers";
import AppCollectionSlider from '../components/AppCollectionSlider';
import WebCollection2 from "../components/WebCollection2"
import Contact from '../components/Contact';
import Footer from "../components/Footer";


function Home() {
  return (
    <div>
      <Slideshow />
      <AboutUs/>
      <Service/>
      <CustomerBenefit/>
      <OurProgrammers/>
      <AppCollectionSlider/>
      <WebCollection2/>
      <Contact/>
      <Footer/>
    </div>
  )
}

export default Home