import React from 'react'
import "../scss/CustomerBenefit.scss"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import { faArrowTrendDown } from '@fortawesome/free-solid-svg-icons'; 
import { faUserTie} from '@fortawesome/free-solid-svg-icons'; 

import { useTranslation } from "react-i18next";

function CustomerBenefit() {
  const { t } = useTranslation();

  return (
    <div className='CustomerBenefit' id='customerBenefit'>
        <div className='row'>
            <div className='col-lg-5'>
            <div class="img-mons">
                <div class="row">
                    <div class="col-md-5 cmd-padding valign" data-aos="fade-right">
                        <div class="imgs img1 imago imgs" >
                            <img src="https://ui-themez.smartinnovates.net/items/avo/avo-dark/img/intro/1.jpg" alt=""/>
                        </div>
                    </div>
                    <div class="col-md-7 cmd-padding" data-aos="fade-right">
                        <div class="imgs img2 imago imgs">
                            <img src="https://ui-themez.smartinnovates.net/items/avo/avo-dark/img/intro/2.jpg" alt=""/>
                        </div>
                        <div class="imgs img3 imago imgs" data-aos="fade-right">
                            <img src="https://ui-themez.smartinnovates.net/items/avo/avo-dark/img/intro/3.jpg" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            <div className='col-lg-6 offset-lg-1 valign'>
                <div className='content'>
                    <div class="sub-title" data-aos="fade-right">
                        <h6>{t("Whtat's the benefit for Customer?")}</h6>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <h3 data-aos="fade-right">
                       {t("Build your Project with Low Cost and High Quality")}
                    </h3>
                    <p data-aos="fade-right">
                        {t("customerBenefit1")}
                    </p>
                    <p  data-aos="fade-right">
                        {t("customerBenefit2")}
                    </p>
                    <p data-aos="fade-right">
                        {t("customerBenefit3")}
                    </p>
                    
                    <div class="ftbox mt-30">
                    <div className="sub-title" data-aos="fade-up">
                        <h6>{t("Benefits :")}</h6>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                        <ul>
                            <li class="wow fadeIn" data-aos="fade-up"  data-aos-delay="600">
                                <span class="icon pe-7s-gleam"></span>
                                <h6 class="custom-font">{t("Skilled")}<br/>{t("Software Engineers")}</h6>
                                <div className='icon'>
                                    <FontAwesomeIcon icon={ faUsers } className='icon'/>
                                </div>
                                <div class="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li class="space wow fadeIn" data-aos="fade-up"  data-aos-delay="800">
                                <span class="icon pe-7s-paint-bucket"></span>
                                <h6 class="custom-font">{t("Reasonable")}<br/>{t("Development Costs")}</h6>
                                <div className='icon'>
                                    <FontAwesomeIcon icon={ faArrowTrendDown } className='icon'/>
                                </div>
                                <div class="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                            <li class="wow fadeIn" data-aos="fade-up"  data-aos-delay="1000">
                                <span class="icon pe-7s-medal"></span>
                                <h6 class="custom-font">{t("Project Manager")}<br/>{t("in every project")}</h6>
                                <div className='icon'>
                                    <FontAwesomeIcon icon={ faUserTie } className='icon'/>
                                </div>
                                <div class="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>            
            </div>
        </div>
    </div>
  )
}
export default CustomerBenefit