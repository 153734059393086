import React from 'react'
import "../scss/OurProgrammers.scss";

/*
import human1 from "../imgs/Picture1.jpg";
import human2 from "../imgs/Picture2.jpg";
import human3 from "../imgs/Picture4.jpg";
import human4 from "../imgs/Picture3.jpg";
*/

import human1 from "../imgs/Picture1.jpg";
import human2 from "../imgs/Picture2.jpg";
import human3 from "../imgs/Picture4.jpg";
import human4 from "../imgs/Picture3.jpg";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';

import { useTranslation } from "react-i18next";

function OurProgrammers() {
  const { t } = useTranslation();

  return (
    <div className="OurProgrammers" id='OurProgrammers'>
        <div className='container'>
        <h3 className='subTitle' data-aos="zoom-in" data-aos-delay="400">{t("Your Source for Skilled Programmers")}</h3>
        <h2 className='title' data-aos="zoom-in" data-aos-delay="600">{t("Our Programmers.")}</h2>
        <div className="row">
            <div className="col-lg-5 valign" >
                <div className="content" data-wow-delay=".5s">
                    <div className="sub-title">
                        <h6>{t("Our Programmers")}</h6>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                    <h3 className="co-tit custom-font mb-15" data-aos="fade-left">{t("We help to create visual strategies.")}</h3>
                        <p data-aos="fade-left">
                        {t("ourProgrammers1")}
                        </p>
                        <p data-aos="fade-left">
                        {t("ourProgrammers2")}
                        </p>
                        <p data-aos="fade-left">
                        {t("ourProgrammers3")}
                        </p>
                    <div classNae="skills-box mt-40">       
                        <ul class="socialEffect__box">
                            <li  data-aos="fade-left">
                                <a class="socialEffect__link icon-instagram" href="https://www.instagram.com/technopressogroup/" target="_blank" >
                                <FontAwesomeIcon icon={ faInstagram }/>
                                </a>
                            </li>
                            <li  data-aos="fade-left"  data-aos-delay="400">
                                <a class="socialEffect__link icon-facebook" href="https://www.facebook.com/technopressogroup" target="_blank" >
                                <FontAwesomeIcon icon={ faFacebook } />
                                </a>
                            </li>
                            <li  data-aos="fade-left"  data-aos-delay="800">
                                <a class="socialEffect__link icon-twitter" href="https://twitter.com/technopresso" target="_blank" >
                                <FontAwesomeIcon icon={ faTwitter } />
                                </a>
                            </li>
                            <li data-aos="fade-left"  data-aos-delay="1000">
                                <a class="socialEffect__link icon-github" href="https://github.com/technopresso" target="_blank" >
                                <FontAwesomeIcon icon={ faGithub } />
                                </a>
                            </li>
                            <li  data-aos="fade-left"  data-aos-delay="1200">
                                <nuxt-link to="/contact" >
                                    <a class="socialEffect__link icon-gmail" href="#">
                                    <FontAwesomeIcon icon={ faEnvelope }/>
                                    </a>
                                </nuxt-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="col-lg-6 offset-lg-1">
                <div className="img-box">
                    <div className="row">
                        <div className="col-sm-6 toright">
                            <div className="full-width" >
                                <div className="sizxl mb-30"  data-aos="fade-left">
                                    <img src={human1} alt="" />
                                </div>
                                <div className="margin-left sizmd"  data-aos="fade-left">
                                    <img src={human2} alt="" />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 toleft valign">
                            <div className="full-width text-left"  data-aos="fade-left">
                                <div className=" sizmd mb-30">
                                    <img src={human3} alt="" />
                                </div>
                                <div className="sizsm"  data-aos="fade-left">
                                    <img src={human4} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mobile-img-box'  data-aos="fade-left">
                <div className='row'>
                    <div className='col-3'>
                        <img src={human1} alt="" />
                    </div>
                    
                    <div className='col-3'  data-aos="fade-left">
                        <img src={human2} alt="" />
                    </div>
                    
                    <div className='col-3'  data-aos="fade-left">
                    <img src={human3} alt="" />
                    </div>
                    
                    <div className='col-3'  data-aos="fade-left">
                    <img src={human4} alt="" />
                    </div>
                </div>
            </div>
            </div>
        </div>
    </div>
  )
}

export default OurProgrammers