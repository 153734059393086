import './App.scss';
import React, { useEffect } from 'react'; 

//routes
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';

//particles
import Particles from './components/Particles';

//Nav
import Navbar from './components/Navbar';

//Coursor
import AnimeCursor from './components/AnimeCursor';

//Scroll to Top
import ScrollToTopButton from './components/ScrollBtn';

//AOS
import AOS from 'aos';
import 'aos/dist/aos.css';

function App() {
  useEffect(() => {
    AOS.init({
      duration: 2500, 
      //delay: 700,
      delay: 300,
      //offset: 350,
      offset: 200,
      breakpoints: {
        // When screen width is less than or equal to 520px
        520: {
          duration: 2500, 
          delay: 100, // Change the duration to 100ms
          offset: 200,
        }
      }
    });
  }, []);

  return (
    <div className="App">
      <Navbar/>
      <ScrollToTopButton/>
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
          </Routes> 
          <AnimeCursor/>
        </Router>
        
      <Particles/>
    </div>
  ); 
}

export default App;
